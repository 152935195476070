html,
body {
  /*scroll-behavior: smooth;*/
  position: relative;
  user-select: none;
  overflow-x: hidden;
  overflow: hidden;

  padding: 0;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: transparent;

  overscroll-behavior-y: none; /* После этого свайп вниз не будет вызывать обновление страницы */

  -webkit-touch-callout: none; /* Отключить контекстное меню при долгом тапе */ 
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body {
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0);
  
}
* {
  /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
  -webkit-tap-highlight-color: transparent;
} 
a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
  font-family: 'Inter';
  font-style: normal;
  user-select: none;
}

::-webkit-scrollbar {
  height: 5px;
  width: 18px;
  background: rgba(0, 0, 0, 0);
  display: none;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}


@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#successAnimationCircle {
  stroke-dasharray: 151px 151px;
  stroke: #FFF;
}

#successAnimationCheck {
  stroke-dasharray: 36px 36px;
  stroke: #FFF;
}

#successAnimationResult {
  fill: #FFF;
  opacity: 0;
}

#successAnimation.animated {
  animation: 1s ease-out 0s 1 both scaleAnimation;
  
  #successAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
               0.3s linear 0.9s 1 both fadeOut;
  }
  
  #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
               0.3s linear 0.9s 1 both fadeOut;
  }
  
  #successAnimationResult {
    animation: 0.3s linear 0.9s both fadeIn;
  }
}

#replay {
  background: rgba(255,255,255,0.2);
  border: 0;
  border-radius: 3px;
  bottom: 100px;
  color: #FFF;
  left: 50%;
  outline: 0;
  padding: 10px 30px;
  position: absolute;
  transform: translateX(-50%);
  &:active {
    background: rgba(255,255,255,0.1);
  }
}


.grecaptcha-badge {
  display: none;
}

.ymaps-2-1-79-image {
  border-radius: 10px;
}
