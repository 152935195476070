.mapContent > :first-child:is(ymaps) {
  //display: none;
}

.section {
  position: relative;
}
.tabsPoint {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  height: 0;
}
.tabsPointBox { 
  position: relative;
  margin: 50px 0 0 50px;
  width: 360px;
  max-height: 360px;
  font: 400 15px/1.733333333333333 'Ubuntu',sans-serif;
  color: rgb(0, 0, 0);
  background: rgba(255, 255, 255);
  box-shadow: 0 2px 24px rgba(203,215,223,.6);
  box-sizing: border-box;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(9.5px);
  border-radius: 25px;
  span {
    position: relative;
    margin: 10px;
    font-weight: bold;
    color: rgb(75, 75, 75);
    text-transform: uppercase;
    text-align: left;
  }
}
.tabsPointBoxItem { 
  position: relative;
  margin: 10px;
  border: 3px solid rgb(235, 235, 235);
  border-radius: 15px;
}
.tabsPointBoxItemOpen { 
  position: relative;
  margin: 10px;
  background: rgba(255, 255, 255, 0.5);
  border: 3px solid rgb(235, 235, 235);
  border-radius: 15px;
}
.tabsPointBoxItemDescription { 
  position: relative;
  padding: 10px;
  text-align: left;
}
.tabsPointBoxItemTitle { 
  position: relative;
  padding: 10px;
  text-align: left;
  text-transform: uppercase;
}
.icon {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -10px;
  fill: currentColor;
  transition: transform 400ms;
}
.canvas {
  position: relative;
  left: 0;
  top: 0;
}
@media only screen and (max-width: 600px) {
  .tabsPoint {
    display: block;
    position: relative;
    height: auto;
  }
  .tabsPointBox { 
    margin: 0;
    width: 100vw;
    box-shadow: none;
  }
}