.flicking-pagination {
  display: flex;
  justify-content: center;
  margin-top: 110px;
}

.flicking-pagination .flicking-pagination-bullet {
  width: 30px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(71, 71, 71);
  margin: 0 5px;
  transition: background-color 0.3s;
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); */
}

.flicking-pagination .flicking-pagination-bullet-active {
  background-color: rgb(214, 214, 214);
}
